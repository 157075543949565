import PropTypes from "prop-types"
import React from "react"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import "../../styles/header.css"

const HeaderImage = React.memo(({ headerImageSrc }) => {
  console.log("Greeting Comp render")
  return (
    <Row style={{ margin: "0" }}>
      <Col sm={12} style={{ padding: "0" }}>
        <Image src={headerImageSrc} class="img-fluid" className="header-img" />
      </Col>
    </Row>
  )
})

HeaderImage.propTypes = {
  headerImageSrc: PropTypes.string,
}

HeaderImage.defaultProps = {
  headerImageSrc: ``,
}

export default HeaderImage
